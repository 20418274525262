export const CATEGORIES_WIDGET_ID = 5;
export const CATEGORIES_VISIBLE_DESKTOP = 6;

export const DEFAULT_CATEGORIES_WIDGET_SLICK_SETTINGS = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: CATEGORIES_VISIBLE_DESKTOP,
    slidesToScroll: CATEGORIES_VISIBLE_DESKTOP,
    initialSlide: 0,
};
