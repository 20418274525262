import { Field } from 'Util/Query';

/** @namespace Pwa/Query/CategoriesWidget/Query/CatalogWidget */
export class CatalogWidget {
    getCategoriesQuery(id, data) {
        const query = new Field('categoriesWidget')
            .addArgument('id', 'Int', id)
            .addArgument('data', 'CategoriesWidgetInput', data)
            .addFieldList(this._getCategoriesWidgetFields());

        return query;
    }

    _getCategoriesWidgetFields() {
        return ['front_title', 'product_counter', this._getCategoriesFields()];
    }

    _getCategoriesFields() {
        return new Field('categories').addFieldList([
            'entity_id',
            'name',
            'url_key',
            'url_path',
            'is_active',
            'image',
            'product_count',
            'view_order',
        ]);
    }
}

export default new CatalogWidget();
