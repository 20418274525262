import { connect } from 'react-redux';

import CategoriesWidgetQuery from 'Query/CategoriesWidget.query';
import { DataContainer } from 'Util/Request/DataContainer';

import CategoriesWidget from './CategoriesWidget.component';
import { CATEGORIES_WIDGET_ID } from './CategoriesWidget.config';

/** @namespace Pwa/Component/CategoriesWidget/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});
/** @namespace Pwa/Component/CategoriesWidget/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
});

/** @namespace Pwa/Component/CategoriesWidget/Container/CategoriesWidgetContainer */
export class CategoriesWidgetContainer extends DataContainer {
    state = {
        front_title: '',
        product_counter: false,
        categories: [],
        isLoading: false,
        slideHeight: 0,
    };

    containerFunctions = {};

    componentDidMount() {
        this.getCatalogWidgetData();
        this.getSlideWidth();
    }

    componentDidUpdate() {
        this.getSlideWidth();
    }

    getSlideWidth() {
        const slideWidth = document?.querySelector('.HpCategories-Item')?.offsetWidth || 0;
        this.setState({ slideHeight: slideWidth });
    }

    getCatalogWidgetData() {
        const { conditionsEncoded, title } = this.props;

        if (!conditionsEncoded) {
            return;
        }

        const data = {
            show_product_counter: 1,
            front_title: title,
            conditions_encoded: conditionsEncoded,
        };

        this.setState({ isLoading: true });
        this.fetchData(
            [CategoriesWidgetQuery.getCategoriesQuery(CATEGORIES_WIDGET_ID, data)],
            ({ categoriesWidget: { front_title, product_counter, categories } }) => {
                this.setState({
                    front_title,
                    product_counter,
                    categories,
                    isLoading: false,
                });
            },
            () => {
                this.setState({
                    front_title: '',
                    product_counter: false,
                    categories: [],
                    isLoading: false,
                });
            }
        );
    }

    containerProps() {
        const { front_title, product_counter, categories, isLoading, slideHeight } = this.state;
        const { device } = this.props;

        return {
            front_title,
            product_counter,
            categories,
            isLoading,
            device,
            slideHeight,
        };
    }

    render() {
        return <CategoriesWidget {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesWidgetContainer);
