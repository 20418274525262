import React, { Component } from 'react';
import SlickSlider from 'react-slick';

import Link from 'Component/Link';
import SliderCounterNav from 'Component/SliderCounterNav';
import SliderNext from 'Component/SliderNext';
import SliderPrev from 'Component/SliderPrev';
import SliderProgressBar from 'Component/SliderProgressBar';

import { CATEGORIES_VISIBLE_DESKTOP, DEFAULT_CATEGORIES_WIDGET_SLICK_SETTINGS } from './CategoriesWidget.config';

import './CategoriesWidget.style.scss';

/** @namespace Pwa/Component/CategoriesWidget/Component/CategoriesWidget */
export class CategoriesWidget extends Component {
    static defaultProps = {
        settings: {},
    };

    state = {
        currentSlide: 1,
    };

    __construct() {
        this.nextSlide = this.nextSlide.bind(this);
        this.previousSlide = this.previousSlide.bind(this);
    }

    nextSlide() {
        this.slider.slickNext();
    }

    previousSlide() {
        this.slider.slickPrev();
    }

    renderCategoriesTitle() {
        const { front_title } = this.props;

        return (
            <h2 block="HpCategories" elem="SectionTitle">
                {front_title}
            </h2>
        );
    }

    renderCategoriesNavigation() {
        const {
            categories,
            device: { isMobile, isTablet },
        } = this.props;

        const { currentSlide } = this.state;
        const pages = Math.ceil(categories.length / CATEGORIES_VISIBLE_DESKTOP);

        if (isTablet || isMobile || !(pages > 1)) {
            return null;
        }

        return (
            <div block="HpCategories" elem="NavigationWrapper">
                <SliderCounterNav
                    currentSlide={currentSlide}
                    numberOfSlides={pages || 0}
                    goToNextSlide={this.nextSlide}
                    goToPrevSlide={this.previousSlide}
                />
            </div>
        );
    }

    renderCategoryBox(category) {
        const { name, url_path, url_key, image } = category;
        const { slideHeight } = this.props;

        return (
            <Link
                to={`${url_path}.html`}
                block="HpCategories"
                elem="Item"
                title={name}
                key={url_key}
                style={{ height: slideHeight }}
            >
                <img block="HpCategories" elem="Image" src={image} alt={name} />
                <span block="HpCategories" elem="Title">
                    {name}
                </span>
            </Link>
        );
    }

    renderCategories() {
        const {
            categories,
            settings,
            device: { isMobile, isTablet },
            slideHeight,
        } = this.props;

        if (!categories) {
            return null;
        }

        const sortedCategories = categories.sort((a, b) => a.view_order - b.view_order);

        if (!isTablet && !isMobile && categories?.length > CATEGORIES_VISIBLE_DESKTOP) {
            return (
                <div block="HpCategories" elem="SliderWrapper" style={{ height: slideHeight }}>
                    <SlickSlider
                        prevArrow={<SliderPrev />}
                        nextArrow={<SliderNext />}
                        {...DEFAULT_CATEGORIES_WIDGET_SLICK_SETTINGS}
                        {...settings}
                        beforeChange={(_, nextSlide) => {
                            this.setState({ currentSlide: nextSlide / CATEGORIES_VISIBLE_DESKTOP + 1 });
                        }}
                        // eslint-disable-next-line no-return-assign
                        ref={(slider) => (this.slider = slider)}
                    >
                        {sortedCategories.map((category) => this.renderCategoryBox(category))}
                    </SlickSlider>
                </div>
            );
        }

        return (
            <div block="HpCategories" elem="Wrapper" style={{ height: !isTablet && !isMobile && slideHeight }}>
                {sortedCategories.slice(0, 6).map((category) => this.renderCategoryBox(category))}
            </div>
        );
    }

    renderProgressBar() {
        const {
            categories,
            device: { isMobile, isTablet },
        } = this.props;

        const { currentSlide } = this.state;
        const pages = Math.ceil(categories.length / CATEGORIES_VISIBLE_DESKTOP);

        if (isTablet || isMobile || !(pages > 1)) {
            return null;
        }

        return <SliderProgressBar currentSlide={currentSlide} numberOfSlides={pages || 0} />;
    }

    render() {
        const { isLoading } = this.props;

        if (isLoading) {
            return null;
        }

        return (
            <div block="HpCategories">
                <div block="HpCategories" elem="TopWrapper">
                    {this.renderCategoriesTitle()}
                    {this.renderCategoriesNavigation()}
                </div>
                {this.renderCategories()}
                {this.renderProgressBar()}
            </div>
        );
    }
}

export default CategoriesWidget;
